@import url('https://fonts.googleapis.com/css2?family=Lexend+Zetta:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Serif+JP:wght@200..900&display=swap');


* {
  font-family: "Noto Serif JP", sans-serif;
}

span[lang="en"] {
  font-family: "Lexend Zetta";
}

.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: clip;
}

ul{
  list-style-type: none;
}

li{
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

h1{
  font-size: 64px;
  color: #374863;
  margin: 0;
  font-weight: 600;
  display: inline-block;
}

h4{
  font-size: 36px;
  color: #374863;
  margin: 0;
  font-weight: 300;
  display: inline-block;
}

h6{
  color: #374863;
  margin: 0;
  font-weight: 200;
  display: inline-block;
}

a{
  display: flex;
  text-decoration: none;
  color: inherit;
  align-items: center;
  justify-content: center
}

/* TopButton */
.scroll-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 1000;
}

.scroll-to-top,
.scroll-to-message {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); 
}

.scroll-to-top:hover,
.scroll-to-message:hover {
  transform: scale(1.1);
  background-color: #f0f0f0;
}

/* Scroll Button: Images */
.scroll-to-message img,
.scroll-to-top img {
  width: 30px;
}

@media (max-width: 600px) {
  .scroll-to-top,
  .scroll-to-message {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 5px; 
    aspect-ratio: 1 / 1; 
  }

  .scroll-to-message img{
    width: 17px;
    height: 17px;
  }

  .scroll-to-top img{
    width: 17px;
  }
}

/* Tooltip for Scroll-to-Message */
.scroll-to-message-container {
  position: relative;
  display: inline-block;
}

.scroll-to-message-container .tooltip {
  display: none;
  position: absolute;
  top: 50%;
  right: 120%;
  font-family: "Lexend Zetta";
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 5s ease-in-out;
  z-index: 1001;
}

.scroll-to-message-container:hover .tooltip {
  display: block;
  opacity: 1;
}

/* Navbar */
nav {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-color: #FFFFFB; */
}

.navbar{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 8.3333%;
  margin-right: 8.3333%;
}

.nav-logo {
  width: 50px;
}

.nav-logo a{
  transform: scaleY(1);
  display: inline-block;
}

.nav-logo img{
  width: 30px;
}

nav ul {
  width: 68%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* position: relative; */
  transition: transform 0.3s ease;
  margin-left: 2%;
}

nav li {
  width: 100%;
  height: 100% ;
  /* margin-left: 5.21%; */
  font-size: 24px;
  color: #374863;
}


.member-btn{
  width: 140px;
  height: 35px;
  background-color: #FFFFFB;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #374863;
  font-size: 24px;
}

.member-btn img a{
  /* transform: scaleY(0.7); */
  display: inline-block;
}

.member-btn img{
  margin-right: 5px;
}

nav button {
  display: none;
}

@media (max-width: 1200px) {
  nav {
    width: 100%;
    height: 86px;
    display: flex;
    justify-content: space-around;
    position: relative;
  }

  .navbar{
    width: 83.33%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 5%;
    padding-right: 5%; */
  }

  .nav-logo {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }  

  .nav-logo a{
    height: 30px;
  }

  .nav-logo img {
    width: 100%;
  }

  nav ul {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 80px;
    right: 0;
    background-color:#e7eaeee2;
    padding-top: 10%;
    padding: 0px;
    z-index:10;
    transform: translateX(200%);
  }

  nav ul li a{
    height: 100%;
  }

  nav ul.active {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    transform: translateX(0);
    padding: 0;
    padding-top: 0%;
    /* padding-bottom: 100vh; */
    flex-direction: column;
    justify-content: flex-start;
  }

  nav li {
    width: 80%;
    height: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5%;
  }

  .member-btn{
    width: 140px;
    height: 35px;
    background-color: #FFFFFB;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #374863;
    font-size: 24px;
    margin-bottom: 100vh;
  }

  .menu-toggle {
    display: block;
    background: none;
    border: none;
    color: #3C3C3C;
    /* font-size: 24px; */
    cursor: pointer;
  }

  .language {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-top: 10%;
  }

  .language a img {
    width: 16px;
    display: block;
  }

  .cn {
    padding-right: 0px;
  }

  .menu-toggle {
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: none;
    padding: 0;
}

.menu-toggle::before,
.menu-toggle::after {
    content: '';
    position: absolute;
    /* width: 100%; */
    height: 0.5px;
    background-color: #374863;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.menu-toggle::before {
    width: 100%;
    transform: translateY(-6px);
}

.menu-toggle::after {
    width: 70%;
    margin-left: 30%;
    transform: translateY(4px);
}

.menu-toggle.active::before {
    width: 100%;
    transform: rotate(45deg) translate(0px, 0px); 
}

.menu-toggle.active::after {
    width: 100%;
    margin-left: 0%;
    transform: rotate(-45deg) translate(0px, 0px);
}
}

@media (max-width: 600px) {
  nav ul, nav ul.active {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    padding-top: 10%;
    padding-bottom: 100vh;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  nav ul li, nav ul.active li {
    height: 50px;
    margin: 5% 0;
    /* margin-right: 17%; */
  }
}

/* Footer */
footer{
  width: 100%;
  height: 200px;
  background-color: #201f1f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.footer-bar{
  width:83.33% ;
  /* height: 200px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8.3333%;
  margin-right: 8.3333%;
  margin-top: 77px;
  text-align: center;
  color: #fffffb;
  font-family: "Lexend Zetta", sans-serif;
}

.footer-bar h6 {
  color: #FFFFFB;
}

.footer-bar ul{
  width: 49.48%;
  display: flex;
  margin-left: 22.6%;
  padding: 0;
  justify-content: flex-end;
}

.footer-bar ul li{
  margin-left: 5.68%;
  color: #FFFFFB;
}

.footer-text{
  width: 83.33%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 56px;
}

.footer-text p{
  width: 300px;
  display: flex;
  justify-content: space-between;
  color: #FFF;
}

.footer-logo{
  width: 150px;
}

.footer-icon{
  display: flex;
  /* justify-content: space-between; */
}

.footer-icon ul{
  width: 512px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-name{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 2%;
}

.footer-name h6{
  color: #FFFFFB;
  font-size: 14px;
  font-weight: bold;
  margin: 0%;
  margin-top: 20%;
  margin-bottom: 20%;
}

@media (max-width: 1200px) {
  .footer-bar{
    width:83.33% ;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }

  .footer-bar ul{
    width: 100%;
    display: flex;
    margin-left:0%;
    margin-top: 10%;
    padding: 0;
    justify-content: center;
  } 

  .footer-logo{
    width: 35%
  }

  .footer-bar ul li{
    margin-left: 2%;
    margin-right: 2%;
  }

  .footer-text{
    width: 83.33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    text-align: center;
    margin-top: 20px;
  }

  .footer-icon{
    width: 50%;
    display: flex;
    /* justify-content: space-between; */
  }

  .footer-icon ul{
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-icon ul img{
    width: 24px;
  }

  .footer-name h6{
    color: #FFFFFB;
    font-size: 0.5rem;
    font-weight: bold;
    margin: 0%;
  }
}

@media(max-width:650px){
  .footer-bar ul li a{
    font-size: 0.5rem;
  }
}

/* Loading 整體容器樣式 */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #374863;
}

.svg-container {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in-out;
}

.svg-container.slideUp {
  flex-direction: row;
  transition: all 1s ease-in-out;
}

.svg-container__buttom {
  z-index: 1;
  animation: slideUp 3s ease-in-out forwards;
}

.svg-container__top {
  /* position: absolute;  */
  z-index: 0;
  animation: slideInFromBehind 3s 1s ease-in-out forwards;
  opacity: 0;
}

@keyframes slideInFromBehind {
  0% {
    transform: translateX(35%);
    opacity: 0;
  }
  50% {
    transform: translateX(-35%);
    opacity: 1;
  }
  65% {
    transform: translateX(-35%);
    opacity: 0;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(0%);
    opacity: 1;
  }
  80% {
    transform: translateY(0%);
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
    opacity: 0;
  }
}

/* TopButton */
.scroll-buttons {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.scroll-to-top,
.scroll-to-message {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #000;
  color: white;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
}

.scroll-to-top:hover,
.scroll-to-message:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

@media (max-width:600px) {
  .scroll-to-top,.scroll-to-message {
    width: 45px;
    height: 45px;
  }

  .scroll-to-message img{
    width: 100%;
    height: 100%;
  }

  .scroll-to-top img{
    width: 17px;
  }
}

/* Homepage */
.homepage-container{
  width: 100%;
  height: 100%;
  background-color: #374863;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage-banner{
  width: 83.3333%;
  height: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px
}

.homepage-banner__text{
  width: 615px;
  height: 545px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 66px;
}

.homepage-banner__img{
  width: 920px;
  height: 650px;
  overflow: hidden;
  position: relative;
  border-radius: 30px;
}

.homepage-banner__img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.homepage-text{
  width: 50%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #FFFFFB; */
}

.homepage-text h4{
  width: 70%;
  color: #FFFFFB;
  text-align: center;
  /* transform: scaleY(0.7); */
}

.background-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  z-index: -99;
}

.background-container img {
  width: 60%;
  height: auto;
  position: absolute;
  top: 35%;
  left: 80%;
  transform: translate(-50%, -80%);
  z-index: -99;
  animation: shake 8s ease-in-out infinite;
}

.background-container-2 img{
  width: 30%;
  height: auto;
  position: absolute;
  top: 80%;
  left: -2%;
  transform: translate(-50%, -80%);
  z-index: -99;
  animation: shake 8s ease-in-out infinite;
}

@media (max-width:1200px) {
  .background-container img {
    width: 80%;
    height: auto;
    position: absolute;
    top: 20%;
    left: 80%;
    transform: translate(-50%, -80%);
    z-index: -99;
  }

  .background-container-2 img{
    width: 50%;
    height: auto; /* 保持圖片比例 */
    position: absolute;
    top: 105%;
    left: 10%;
    transform: translate(-50%, -80%);
    z-index: -99;
    animation: shake 8s ease-in-out infinite;
  }
}

@keyframes shake {
  0%, 100% {
    transform: translate(-50%, -50%) rotate(0deg); /* 初始和結束位置 */
  }
  25% {
    transform: translate(-60%, -60%) rotate(-5deg); /* 左上方晃動 */
  }
  50% {
    transform: translate(-50%, -50%) rotate(5deg); /* 右下方晃動 */
  }
  75% {
    transform: translate(-40%, -40%) rotate(-5deg); /* 右上方晃動 */
  }
}

@keyframes shake {
  0%, 100% {
    transform: translate(-50%, -50%) rotate(0deg); /* 初始和結束位置 */
  }
  25% {
    transform: translate(-60%, -60%) rotate(-5deg); /* 左上方晃動 */
  }
  50% {
    transform: translate(-50%, -50%) rotate(5deg); /* 右下方晃動 */
  }
  75% {
    transform: translate(-40%, -40%) rotate(-5deg); /* 右上方晃動 */
  }
}

@media (max-width:1200px) {
  .homepage-banner{
    width: 83.3333%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    position: relative;
  }

  .homepage-banner__text{
    width: 83.3333%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: 0px;
    /* top: 43px;
    bottom: 43px; */
    position: absolute;
    z-index: 1;
  }

  .homepage-banner__text h1{
    font-size: 40px;
    color: #FFFFFB;
  }
  
  .homepage-banner__text h4{
    font-size: 16px;
    color: #FFFFFB;
  }

  .homepage-banner__img{
    width: 100%;
    height: 650px;
    overflow: hidden;
    /* position: relative; */
    border-radius: 30px;
  }

  .homepage-text h4{
    width: 70%;
    font-size: 16px;
  }
}

@media (max-width:765px) {
  .homepage-banner{
    width: 83.3333%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 29px;
    position: relative;
  }

  .homepage-text{
    width: 100%;
    height: 60%;
    padding: 10%;
  }
}

/* Shortlink-page */
.user-page{
  width: 100%;
  background-color: #1E1C1C;
  display: flex;
  justify-content: center;
}

.user{
  width: 83.3333%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10%;
}

.user-contact{
  width: 50%;
  margin-right: 10%;
}

.user-contact h2{
  color: #FFF;
  margin: 0%;
}

.user-contact p{
  color: #FFF;
  margin: 0%;
}

.user-link{
  width: 83.3333%;
}

.user-link ul li{
  background-color: #FFF;
}

.love {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* LoveCard */
#envelope {
  position: relative;
  width: 280px;
  height: 180px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-left: auto;
  margin-right: auto;
  top: 150px;
  background-color: #6e6e6e; /* Warm romantic red */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.front {
  position: absolute;
  width: 0;
  height: 0;
  z-index: 3;
}

.flap {
  border-left: 140px solid transparent;
  border-right: 140px solid transparent;
  border-bottom: 82px solid transparent;
  border-top: 98px solid #292828; /* Same romantic red */
  transform-origin: top;
  pointer-events: none;
}

.pocket {
  border-left: 140px solid #473737; /* Soft pinkish red for the pocket */
  border-right: 140px solid #473737;
  border-bottom: 90px solid #201f1f; /* Lighter romantic red */
  border-top: 90px solid transparent;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.letter {
  position: relative;
  background-color: #fff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 90%;
  top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0 2px 26px rgba(0, 0, 0, 0.12);
  font-family: "Dancing Script", cursive;
}

.letter:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.words {
  position: absolute;
  left: 10%;
  width: 80%;
  height: 14%;
}

.words.line1 {
  top: 10%;
  font-size: 0.8rem;
  border-bottom: 1px solid rgb(202, 202, 202);
}

.words.line2 {
  top: 30%;
  font-size: .8rem;
  text-align: center;
  border-bottom: 1px solid rgb(202, 202, 202);
}

.words.line3 {
  top: 50%;
  font-size: 1.1rem;
  text-align: center;
  border-bottom: 1px solid rgb(202, 202, 202);
}

.words.line4 {
  top: 70%;
  font-size: 1.1rem;
  text-align: center;
  border-bottom: 1px solid rgb(202, 202, 202);
}

.open .flap {
  transform: rotateX(180deg);
  transition: transform 0.4s ease, z-index 0.6s;
  z-index: 1;
}

.close .flap {
  transform: rotateX(0deg);
  transition: transform 0.4s 0.6s ease, z-index 1s;
  z-index: 5;
}

.close .letter {
  transform: translateY(0px);
  transition: transform 0.4s ease, z-index 1s;
  z-index: 1;
}

.open .letter {
  transform: translateY(-100px);
  transition: transform 0.4s 0.6s ease, z-index 0.6s;
  z-index: 2;
}

.hearts {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  z-index: 2;
}

.heart {
  position: absolute;
  bottom: 0;
  right: 10%;
  pointer-events: none;
}

.heart:before,
.heart:after {
  position: absolute;
  content: "";
  left: 50px;
  top: 0;
  width: 50px;
  height: 80px;
  background: #f5f2f2; /* Deep romantic pink */
  border-radius: 50px 50px 0 0;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
  pointer-events: none;
}

.heart:after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.close .heart {
  opacity: 0;
  animation: none;
}

.a1 {
  left: 20%;
  transform: scale(0.6);
  opacity: 1;
  animation: slideUp 4s linear 1, sideSway 2s ease-in-out 4 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}

.a2 {
  left: 55%;
  transform: scale(1);
  opacity: 1;
  animation: slideUp 5s linear 1, sideSway 4s ease-in-out 2 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}

/* .a3 {
  left: 10%;
  transform: scale(0.8);
  opacity: 1;
  animation: slideUp 7s linear 1, sideSway 2s ease-in-out 6 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
} */

.a4 {
  left: 0%;
  transform: scale(1.0);
  opacity: 1;
  animation: slideUp 7s linear 1, sideSway 3s ease-in-out 4 alternate;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}

@keyframes slideUp {
  0% {
    top: 0;
  }
  100% {
    top: -600px;
  }
}

@keyframes sideSway {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}

body {
  background-color: #827f85; /* Soft lavender for a romantic, dreamy feel */
}

.envelope-wrapper {
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.reset {
  text-align: center;
  margin-top: 20px; /* Added margin for better spacing */
}

.reset button {
  z-index: 10; /* Ensure the button is on top */
  font-weight: 800;
  font-style: normal;
  transition: all 0.1s linear;
  -webkit-appearance: none;
  background-color: transparent;
  border: solid 2px #ebe6e3;
  border-radius: 4px;
  color: #e6d7d6;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  margin: 5px;
  padding: 10px;
  line-height: 1em;
  text-decoration: none;
  min-width: 120px;
  cursor: pointer;
}

.reset button:hover {
  background-color: #635e5e;
  color: #fff;
}

.envelope-wrapper{
  height: 80vh;
}